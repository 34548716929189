export const ADMIN_MENU = [
    {
        name: 'Inicio',
        url: '',
        icon: 'fas fa-home'
    },
    {
        title: true,
        name: 'Administración'
    },

    {
        name: 'Revistas',
        url: '/admin/revistas',
        icon: 'fas fa-newspaper'
    },
    {
        name: 'Autores',
        url: '/admin/autores',
        icon: 'fas fa-book-reader'
    },
    {
        name: 'Países',
        url: '/admin/paises',
        icon: 'fas fa-globe-americas'
    },
    {
        name: 'Importador',
        icon: 'fas fa-file-import',
        children: [
            {
                name: 'Instrucciones',
                url: '/admin/instrucciones',
            },
            {
                name: 'Importar índices',
                url: '/admin/importer',
            },
            {
                name: 'Importar números',
                url: '/admin/pdf',
            },
        ]
    },
    {
        name: 'Páginas estáticas',
        icon: 'fas fa-file-alt',
        children: [
            {
                name: 'Proyecto',
                url: '/admin/estatica/proyecto',
            }, {
                name: 'Equipo',
                url: '/admin/estatica/equipo',
            }, {
                name: 'Contacto',
                url: '/admin/estatica/contacto',
            },
        ]
    },
    {
        name: 'Usuarios',
        url: '/admin/usuarios',
        icon: 'fas fa-users'
    },
];
