import {NgModule} from '@angular/core';
import {
    NgbAlertModule,
    NgbDateAdapter,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule
} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {NgSelectConfig, NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Principal} from 'app/security/principal.service';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {CustomFormsModule} from 'ngx-custom-validators';
import {FwFilesConfig, FwFilesModule} from 'fw-files';
import {
    FwAuthModule,
    FwCommonConfig,
    FwCommonModule,
    FwFormsConfig,
    FwFormsModule,
    FwPaginationConfig,
    FwPaginationModule,
    FwPrincipalService,
    NgbDateStringAdapter
} from 'fw-core';
import {AlertErrorComponent} from 'app/shared/alert-error.component';
import {ButtonsModule} from 'app/shared/buttons/buttons.module';
import {SearchSelectComponent} from './search-select/search-select.component';
import {AutorMultiSelectorComponent} from 'app/shared/list-multi-selector/autor-multi-selector.component';
import {StaticPagesConfigService} from 'fw-staticpages';
import {YearFormatterPipe} from './year-formatter.pipe';
import {AutorFormatterPipe} from 'app/shared/autor-formatter.pipe';
import {NumberRangeFormatterPipe} from 'app/shared/number-range-formatter.pipe';

@NgModule({
    imports: [FormsModule, CustomFormsModule, FwCommonModule, FwAuthModule, FwFilesModule,
        CommonModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, FwFormsModule,
        FwPaginationModule, NgOptionHighlightModule,
        RouterModule, NgSelectModule, NgbAlertModule, ButtonsModule],
    declarations: [AlertErrorComponent, SearchSelectComponent, AutorMultiSelectorComponent,
        YearFormatterPipe, AutorFormatterPipe, NumberRangeFormatterPipe],
    providers: [
        {provide: NgbDateAdapter, useClass: NgbDateStringAdapter},
        {provide: FwPrincipalService, useClass: Principal}],
    exports: [FwFormsModule, CustomFormsModule, AlertErrorComponent,
        NgSelectModule, FormsModule, CommonModule, FwAuthModule, FwCommonModule, SearchSelectComponent,
        NgbDatepickerModule, NgbModalModule, NgbDropdownModule, NgbAlertModule, ButtonsModule,
        FwPaginationModule, AutorMultiSelectorComponent, YearFormatterPipe, AutorFormatterPipe, NumberRangeFormatterPipe]
})
export class SharedModule {

    constructor(private config: NgSelectConfig, private commonConfig: FwCommonConfig,
                private paginationConfig: FwPaginationConfig, private formsConfig: FwFormsConfig,
                public staticConfig: StaticPagesConfigService,
                private filesConfig: FwFilesConfig) {

        this.config.notFoundText = $localize`Not found`;

        this.commonConfig.i18n = {
            confirmOk: $localize`Sí`,
            confirmCancel: $localize`No`,
            confirmTitle: $localize`Confirmación de borrado`,
            connectionErrorToastMessage: $localize`No se ha podido establecer conexión con el servidor, comprueba que tienes acceso a internet. Si el problema persiste contacta con el administrador.`
        };

        this.paginationConfig.pageSize = 20;

        this.formsConfig.i18n = {
            save: $localize`Guardar`,
            emptyList: $localize`No hay elementos`,
            validationDefault: $localize`Error de validación: `,
            validationEmail: $localize`El email no es válido`,
            validationRequired: $localize`Campo requerido`,
            validationInvalidPattern: $localize`Patrón incorrecto`,
            validationMaxLength1: $localize`Debe tener como máximo `,
            validationMaxLength2: $localize` caracteres`,
            validationMinLength1: $localize`Debe tener como mínimo `,
            validationMinLength2: $localize` caracteres`,
            booleanNo: $localize`No`,
            booleanYes: $localize`Sí`,
        };

        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlFicheroTemporal = 'api/files';
        this.filesConfig.urlImagenDefault = 'content/img/default.jpg';
        this.filesConfig.i18n = {
            imageLoading: $localize`Cargando`,
            imageChange: $localize`Cambiar`
        };

        this.staticConfig.pathPublicView = 'estatica';
        this.staticConfig.i18n = {
            visualize: $localize`Visualizar`,
            addLanguage: $localize`Añadir lenguaje`,
            confirmationMsg: $localize`¿Estás seguro de que quieres borrar esta página estática?`,
            content: $localize`Contenido`,
            delete: $localize`Borrar`,
            edit: $localize`Editar`,
            header: $localize`Identificador`,
            newPage: $localize`Nueva página estática`,
            notFoundMessage: $localize`Página estática no encontrada`,
            title: $localize`Título`,
            placeholder: $localize`Lenguajes a añadir`,
            successDeletion: $localize`Se ha borrado satisfactoriamente la página`,
            noLanguages: $localize`¡Se necesita al menos un lenguaje!`
        };

    }
}
