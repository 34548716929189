import {Component, OnInit} from '@angular/core';

@Component({
    templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

    ngOnInit() {

    }
}
