export const USER_MENU = [
    {
        title: true,
        name: 'Portada',
        url: '/'

    },
    {
        name: 'Proyecto',
        url: 'estatica/proyecto'

    },
    {
        name: 'Equipo',
        url: 'estatica/equipo'

    },
    {
        name: 'Publicaciones',
        url: '/publicaciones'
    },
    {
        name: 'Contacto',
        url: '/contacto'
    }
];
