<app-header
    [fixed]="true"
    mobileSidebarToggler="{{account && account?.authorities.includes('ROLE_ADMIN')}}">
    <a class="navbar-brand" routerLink="/">
        <img src="content/img/logo_header.png" class="logo">
    </a>

    <ul class="nav navbar-nav ml-auto dropdown-user">

        <a [routerLink]="loginState" id="login" *ngIf="!account?.authorities || account?.authorities.length === 0"
           i18n>
            Entrar
        </a>

        <li class="nav-item dropdown" ngbDropdown placement="bottom right" *ngIf="isAuthenticated()">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
               aria-expanded="false"
               ngbDropdownToggle (click)="false">
                <img [src]="getImageUrl()" class="img-avatar" alt="admin@bootstrapmaster.com"/> {{account?.login}}
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <div class="dropdown-header text-center"><strong>Mi cuenta</strong></div>
                <a class="dropdown-item" routerLink="/account/settings" ngbDropdownItem><i class="fas fa-user"></i>
                    <span i18n>Profile</span></a>
                <a class="dropdown-item" routerLink="/account/password" ngbDropdownItem><i
                    class="fas fa-wrench"></i>
                    <span i18n>Change password</span></a>
                <div class="divider"></div>
                <a class="dropdown-item" ngbDropdownItem href="#" (click)="logout()" id="logout" i18n><i
                    class="fas fa-lock"></i>Exit</a>
            </div>
        </li>
    </ul>

</app-header>

<div class="app-body">
    <app-sidebar [fixed]="true" [display]="mostrarSidebar" *ngIf="account?.authorities.includes('ROLE_ADMIN')">
        <app-sidebar-nav [navItems]="navItems"></app-sidebar-nav>
    </app-sidebar>

    <main class="main">
        <div class="breadcrumbs-container" *ngIf="crumbs.length > 0">
            <fw-breadcrumbs></fw-breadcrumbs>
        </div>
        <fw-router-outlet></fw-router-outlet>
    </main>
</div>

<div class="user-suplantado" *ngIf="account?.loginUserOriginal !== null">
        <span class="info-user-suplantado" i18n>
            You are impersonating user '{{account?.login}}'
        </span>
    <a target="_self" href="api/logout/impersonate" i18n>Return to your account '{{account?.loginUserOriginal}}'</a>
</div>

<footer class="app-footer">
    <span><a href="http://enxenio.es">Enxenio </a> &copy; 2021.</span>
    <div i18n class="version"> Version {{version}}</div>
    <div class="ml-auto">
        <fw-selector-idioma></fw-selector-idioma>
    </div>
</footer>
