import {NgModule} from '@angular/core';
import {EditButtonComponent} from 'app/shared/buttons/edit-button/edit-button.component';
import {CancelButtonComponent} from 'app/shared/buttons/cancel-button/cancel-button.component';
import {DeleteButtonComponent} from 'app/shared/buttons/delete-button/delete-button.component';

@NgModule({
    declarations: [CancelButtonComponent, DeleteButtonComponent, EditButtonComponent],
    imports: [],
    exports: [CancelButtonComponent, DeleteButtonComponent, EditButtonComponent]
})
export class ButtonsModule {
}
