import {Component, Directive, ElementRef, HostBinding, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
    selector: '[appNavDropdown]'
})
export class NavDropdownDirective {

    constructor(private el: ElementRef) {
    }

    toggle() {
        this.el.nativeElement.classList.toggle('open');
    }
}

/**
 * Allows the dropdown to be toggled via click.
 */
@Directive({
    selector: '[appNavDropdownToggle]'
})
export class NavDropdownToggleDirective {
    constructor(private dropdown: NavDropdownDirective) {
    }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        $event.preventDefault();
        this.dropdown.toggle();
    }
}

@Component({
    selector: 'app-sidebar-nav',
    template: `
        <ul class="nav">
            <ng-template ngFor let-navitem [ngForOf]="navItems">
                <li *ngIf="isDivider(navitem)" class="nav-divider"></li>
                <ng-template [ngIf]="isTitle(navitem)">
                    <app-sidebar-nav-title [title]='navitem'></app-sidebar-nav-title>
                </ng-template>
                <ng-template [ngIf]="!isDivider(navitem)&&!isTitle(navitem)">
                    <app-sidebar-nav-item [item]='navitem'></app-sidebar-nav-item>
                </ng-template>
            </ng-template>
        </ul>`
})
export class AppSidebarNavComponent implements OnInit {
    @Input() navItems: any;

    @HostBinding('class.sidebar-nav') true;
    @HostBinding('attr.role') role = 'nav';

    public isDivider(item) {
        return item.divider ? true : false;
    }

    public isTitle(item) {
        return item.title ? true : false;
    }

    constructor() {
    }

    ngOnInit(): void {
    }
}

@Component({
    selector: 'app-sidebar-nav-item',
    template: `
        <li *ngIf="!isDropdown(); else dropdown" [ngClass]="hasClass() ? 'nav-item ' + item.class : 'nav-item'">
            <app-sidebar-nav-link [isChild]="isChild" [link]='item'></app-sidebar-nav-link>
        </li>
        <ng-template #dropdown>
            <li [ngClass]="hasClass() ? 'nav-item nav-dropdown ' + item.class : 'nav-item nav-dropdown'"
                [class.open]="isActive()"
                routerLinkActive="open"
                appNavDropdown>

                <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle>
                    <i *ngIf="item.icon" class="nav-icon {{ item.icon }}"></i>
                    {{ item.name }}
                    <span *ngIf="item.badge" [ngClass]="'badge badge-' + item.badge.variant">{{ item.badge.text
                        }}</span>
                </a>
                <ul class="nav-dropdown-items">
                    <ng-template ngFor let-child [ngForOf]="item.children">
                        <app-sidebar-nav-item [isChild]="true" [item]='child'></app-sidebar-nav-item>
                    </ng-template>
                </ul>

            </li>
        </ng-template>
    `,
    styles: ['.nav-dropdown-toggle { cursor: pointer; }']
})
export class AppSidebarNavItemComponent implements OnInit {
    @Input() item: any;
    @Input() isChild = false;

    public hasClass() {
        return this.item.class ? true : false;
    }

    public isDropdown() {
        return this.item.children ? true : false;
    }

    public thisUrl() {
        return this.item.url;
    }

    public isActive() {
        return this.router.isActive(this.thisUrl(), false);
    }

    constructor(private router: Router, private el: ElementRef) {
    }

    ngOnInit() {
        /* Replace(this.el);*/
    }

}

@Component({
    selector: 'app-sidebar-nav-link',
    template: `
        <a *ngIf="!isExternalLink(); else external"
           [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'"
           [ngStyle]="isChild ? {'padding-left': '30px','font-size': '14px'}: null"
           routerLinkActive="active"
           [routerLink]="[link.url]"
           (click)="hideMobile()">
            <i *ngIf="isIcon()" class="nav-icon {{ link.icon }}"></i>
            {{ link.name }}
            <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
        </a>
        <ng-template #external>
            <a [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'" href="{{link.url}}">
                <i *ngIf="isIcon()" class="nav-icon {{ link.icon }}"></i>
                {{ link.name }}
                <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
            </a>
        </ng-template>
    `
})
export class AppSidebarNavLinkComponent implements OnInit {
    @Input() link: any;
    @Input() isChild = false;

    public hasVariant() {
        return this.link.variant ? true : false;
    }

    public isBadge() {
        return this.link.badge ? true : false;
    }

    public isExternalLink() {
        return this.link.url.substring(0, 4) === 'http' ? true : false;
    }

    public isIcon() {
        return this.link.icon ? true : false;
    }

    public hideMobile() {
        if (document.body.classList.contains('sidebar-show')) {
            document.body.classList.toggle('sidebar-show');
        }
    }

    constructor(private router: Router, private el: ElementRef) {
    }

    ngOnInit() {
        /*Replace(this.el);*/
    }
}

@Component({
    selector: 'app-sidebar-nav-title',
    template: ''
})
export class AppSidebarNavTitleComponent implements OnInit {
    @Input() title: any;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit() {
        const nativeElement: HTMLElement = this.el.nativeElement;
        const li = this.renderer.createElement('li');
        const name = this.renderer.createText(this.title.name);

        this.renderer.addClass(li, 'nav-title');

        if (this.title.class) {
            const classes = this.title.class;
            this.renderer.addClass(li, classes);
        }

        if (this.title.wrapper) {
            const wrapper = this.renderer.createElement(this.title.wrapper.element);

            this.renderer.appendChild(wrapper, name);
            this.renderer.appendChild(li, wrapper);
        } else {
            this.renderer.appendChild(li, name);
        }
        this.renderer.appendChild(nativeElement, li);
        /* Replace(this.el);*/
    }
}
