import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import { AlertErrorService } from 'fw-core';

const STATUS_LOGIN_ERROR_OR_SESSION_EXPIRED = 401;

export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertErrorService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(
            () => {
            },
            (err: HttpErrorResponse) => {
                if (err.status !== STATUS_LOGIN_ERROR_OR_SESSION_EXPIRED) {
                    this.alertService.show(err);
                }
            }
        ));
    }
}
