<div xmlns="http://www.w3.org/1999/html">

    <header class=" navbar public-header">
        <div class="hamburger-placer mx-auto col-11">
            <a class="navbar-toggler ml-0" href="/"><img class="logo-surhi" *ngIf="!isHome" alt="small logo"
                                                         src="content/img/logo_header.png">
            </a>
            <div class="buttongroup">
                <div *ngIf="isPublicaciones">
                    <button *ngIf="!hiddenSearcher" class="mr-2 navbar-toggler" type="button" data-toggle="collapse"
                            (click)="toggleSearcher($event)"
                            aria-label="Toggle searcher">
                        <span class="search-icon"></span>
                    </button>

                    <div *ngIf="hiddenSearcher" class="inner-addon left-addon"
                         style="display:flex; flex-direction: row">

                        <i class="lupa"></i>
                        <input class="form-control" type="text" placeholder="Introduce título o autor..."
                               aria-label="Search" [(ngModel)]="textoBusqueda"
                               (keyup)="buscarPal()" autofocus>

                        <button class="close-buscador" (click)="toggleSearcher($event)">&#10005;</button>
                    </div>
                </div>
                <button class="navbar-toggler " type="button" data-toggle="collapse"
                        data-target="#navbarToggleExternalContent"
                        (click)="toggleSidebar($event)"
                        aria-controls="navbarToggleExternalContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span class="hamburger fas fa-bars"></span>
                </button>

            </div>
        </div>
    </header>

    <div style="height: 0; max-height: 0">

        <app-sidebar class="public-nav" style="max-height: 100vh; height: 100vh;    margin-left: 0;" [fixed]="true"
                     [class.hidden]="hiddenSidebar"
                     [class.visible]="!hiddenSidebar"
        ><span class="cross"
               (click)="toggleSidebar($event)">&#10005;</span>
            <ul class="nav   mx-auto margin-top-nav ml-5" (click)="toggleSidebar($event)">
                <li *ngFor="let item of navItems" class="item">
                    <div class="menu-title " *ngIf="item.title">
                        <a routerLinkActive="active"
                           [routerLinkActiveOptions]="{ exact: true }"
                           [routerLink]="[item.url]"><p class="menu mx-0 ">{{item.name}}  </p>
                        </a>
                        <i class="square"></i>
                    </div>
                    <a *ngIf="!item.title" routerLinkActive="active" [routerLink]="[item.url]"
                    ><p class="  menu subtitle">{{item.name}}</p></a>
                </li>
            </ul>
            <button class="sidebar-minimizer" type="button" appSidebarMinimizer></button>
        </app-sidebar>
    </div>
</div>

<div class="app-body  ">


    <main class="public-main " (click)="globalToggle($event)">
        <div class="breadcrumbs-container">
            <fw-breadcrumbs></fw-breadcrumbs>
        </div>
        <fw-router-outlet></fw-router-outlet>
    </main>
</div>


<footer class="public-footer">
    <div class="mx-auto col-11">
        <div class="row">
            <div class="col-md-6 links">
                <!--        <div class="mb-4">-->
                <!--            <a class=" mr-3">Condiciones de uso</a>-->
                <!--            <a class=" mr-3">Aviso de privacidad</a>-->
                <!--            <a class=" ">Cookies</a>-->
                <!--        </div>-->
                <div class="m-auto">
                    <span>&copy; 2021 SURHI. Todos los derechos reservados</span>
                </div>
            </div>

            <div class="col-md-6 logos">
                <img class="col-xs-6 logo logo-udc"
                     style="width: 178px;max-width: 178px;margin-top: 14px;padding: 0; height: 22px;"
                     src="content/img/02_Simbolo_logo_BN.png" alt="UDC"/>

                <img class="col-xs-6 logo logo-ministerio"
                     style="width: 174px;max-width: 174px;height: 49px; padding: 0;"
                     src="content/assets/Logotipo_del_Ministerio_de_Ciencia_e_Innovación.svg" alt="GOB"/>
            </div>
        </div>
    </div>
</footer>

