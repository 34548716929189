/* tslint:disable */
import { Page, Pageable, Slice, Sort } from 'fw-core';

import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Dictionary<V> {
    [key: string]: V;
}

export type Instant = {};

export enum TipoPublicacion { ILUSTRACION = "ILUSTRACION", TEXTO = "TEXTO" }

export interface AccountDTO {
    authorities?: string[];
    avatar?: FwFileDTO;
    avatarUrl?: string;
    email?: string;
    firstName?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    loginUserOriginal?: string;
    validated?: boolean;
    version?: number;
}

export interface AuditDTO {
    createdBy?: string;
    createdDate?: Instant;
    lastModifiedBy?: string;
    lastModifiedDate?: Instant;
}

export interface AutorDTO {
    apellidos?: string;
    audit?: AuditDTO;
    id?: number;
    label?: string;
    nombre?: string;
}

export interface AutorFilter {
    apellidos?: string;
    conjuntoNombre?: string;
    nombre?: string;
}

export interface AutorListDTO {
    apellidos?: string;
    id?: number;
    label?: string;
    nombre?: string;
}

export interface AutorPaginaDTO {
    numero?: string;
    paginas?: PublicPaginaDTO[];
    posicionNumero?: number;
}

export interface AutorPublicacionDTO {
    autor?: AutorDTO;
    id?: number;
    traductor?: boolean;
}

export interface BasicDTO {
    id?: number;
    label?: string;
    version?: number;
}

export interface BasicPublicacionPaginaDTO {
    id?: number;
    pagina?: BasicDTO;
    publicacion?: BasicDTO;
}

export interface BuscadorPublicacionDTO {
    autores?: AutorDTO[];
    idNumero?: number;
    nombreNumero?: string;
    posicionPagina?: number;
    primeraPagina?: string;
    revistaDTO?: BuscadorRevistaDTO;
    titulo?: string;
}

export interface BuscadorPublicacionNumeroDTO {
    publicPublicacionSearchDTO?: BuscadorPublicacionDTO[];
    tituloNumero?: string;
}

export interface BuscadorRevistaDTO {
    anioFin?: number;
    anioInicio?: number;
    id?: number;
    numeros?: PublicNumeroDTO[];
    pais?: BasicDTO;
    presentacion?: string;
    slug?: string;
    titulo?: string;
}

export interface ContactoDTO {
    content?: string;
    email?: string;
    nombre?: string;
}

export interface DocumentacionDTO {
    indiceImagenes?: FwFileDTO;
    indiceOnomastico?: FwFileDTO;
    indiceTextos?: FwFileDTO;
    revista?: BasicDTO;
}

export interface EntityDTO {
    field?: boolean;
    name?: string;
    type?: FieldTypeDTO;
}

export interface FieldTypeDTO {
    className?: string;
    genericClass?: string;
    genericClassEnum?: boolean;
}

export interface FwFileDTO {
    id?: number;
    name?: string;
    temporalFileName?: string;
}

export interface InvestigadorDTO {
    apellidos?: string;
    email?: string;
    id?: number;
    nombre?: string;
    otrosDatos?: string;
    url?: string;
}

export interface InvestigadorFilter {
    apellidos?: string;
    email?: string;
    nombre?: string;
    otrosDatos?: string;
    url?: string;
}

export interface InvestigadorListDTO {
    apellidos?: string;
    email?: string;
    id?: number;
    nombre?: string;
    otrosDatos?: string;
    url?: string;
}

export interface KeyAndPasswordDTO {
    key?: string;
    newPassword?: string;
}

export interface LanguageDTO {
    code?: string;
    default?: boolean;
    name?: string;
    translation?: string;
}

export interface NumeroDTO {
    desfasePdf?: number;
    id?: number;
    paginaInicio?: number;
    paginas?: PaginaDTO[];
    pdfCompleto?: FwFileDTO;
    posicion?: number;
    revista?: BasicDTO;
    titulo?: string;
}

export interface NumeroFilter {
    hasPdf?: boolean;
    revista?: BasicDTO;
    titulo?: string;
}

export interface NumeroListDTO {
    id?: number;
    paginas?: BasicDTO;
    posicion?: number;
    revista?: BasicDTO;
    titulo?: string;
}

export interface NumeroPaginas {
    paginas?: string[];
    titulo?: string;
}

export interface PaginaDTO {
    id?: number;
    imagen?: FwFileDTO;
    nombre?: string;
    numero?: BasicDTO;
    posicion?: number;
    publicaciones?: PublicacionPaginaDTO[];
}

export interface PaisDTO {
    audit?: AuditDTO;
    id?: number;
    nombre?: string;
}

export interface PaisFilter {
    nombre?: string;
}

export interface PaisListDTO {
    id?: number;
    nombre?: string;
}

export interface PasswordChangeDTO {
    currentPassword?: string;
    newPassword?: string;
}

export interface PublicAutorDTO {
    apellidos?: string;
    label?: string;
    nombre?: string;
    paginas?: AutorPaginaDTO[];
}

export interface PublicBuscadorPublicacionesRevistaDTO {
    buscadorRevistaDTO?: BuscadorRevistaDTO;
    publicPublicacionSearchGroupNumbers?: BuscadorPublicacionNumeroDTO[];
}

export interface PublicNumeroDTO {
    idPortada?: number;
    posicion?: number;
    titulo?: string;
}

export interface PublicPaginaDTO {
    nombre?: string;
    posicion?: number;
}

export interface PublicPublicacionDTO {
    autores?: AutorDTO[];
    numeroPaginas?: NumeroPaginas[];
    posicionPagina?: number;
    primeraPagina?: number;
    titulo?: string;
}

export interface PublicPublicacionVisorDTO {
    autores?: AutorDTO[];
    posicionPagina?: number;
    tipo?: TipoPublicacion;
    titulo?: string;
    traductores?: AutorDTO[];
}

export interface PublicRevistaCountryDTO {
    pais?: BasicDTO;
    revistas?: PublicRevistaListDTO[];
}

export interface PublicRevistaDTO {
    anioFin?: number;
    anioInicio?: number;
    autores?: PublicAutorDTO[];
    ilustaciones?: PublicPublicacionDTO[];
    numeros?: PublicNumeroDTO[];
    presentacion?: string;
    textos?: PublicPublicacionDTO[];
    titulo?: string;
}

export interface PublicRevistaListDTO {
    anioFin?: number;
    anioInicio?: number;
    id?: number;
    portada?: FwFileDTO;
    posicion?: number;
    slug?: string;
    titulo?: string;
}

export interface PublicacionDetailsDTO {
    autores?: AutorPublicacionDTO[];
    genero?: string;
    id?: number;
    notas?: string;
    paginas?: BasicPublicacionPaginaDTO[];
    posicionPaginas?: number[];
    tipo?: TipoPublicacion;
    tipologia?: string;
    titulo?: string;
}

export interface PublicacionFilter {
    id?: number;
    nombreAutor?: string;
    tipo?: TipoPublicacion;
    titulo?: string;
}

export interface PublicacionInfoFilter {
    numPos?: number;
    paginas?: number[];
    revSlug?: string;
}

export interface PublicacionPaginaDTO {
    pagina?: BasicDTO;
    publicacion?: PublicacionDetailsDTO;
}

export interface RevistaBuscadorFilter {
    nombrePais?: string;
    titulo?: string;
}

export interface RevistaDTO {
    anioFin?: number;
    anioInicio?: number;
    id?: number;
    numeros?: BasicDTO;
    pais?: BasicDTO;
    portada?: FwFileDTO;
    posicion?: number;
    presentacion?: string;
    titulo?: string;
}

export interface RevistaFilter {
    pais?: BasicDTO;
    posicion?: number;
    presentacion?: string;
    titulo?: string;
}

export interface RevistaListDTO {
    id?: number;
    numeros?: BasicDTO;
    pais?: BasicDTO;
    posicion?: number;
    presentacion?: string;
    titulo?: string;
}

export interface StaticDTO {
    descriptions?: StaticI18nListDTO[];
    id?: number;
    name?: string;
}

export interface StaticFilter {
    name?: string;
}

export interface StaticI18nDTO {
    content?: string;
    id?: number;
    language?: LanguageDTO;
    name?: string;
    title?: string;
}

export interface StaticI18nListDTO {
    content?: string;
    id?: number;
    language?: LanguageDTO;
    title?: string;
}

export interface StaticListDTO {
    id?: number;
    languages?: LanguageDTO[];
    name?: string;
}

export interface UserBasicDTO {
    id?: number;
    label?: string;
    login?: string;
    version?: number;
}

export interface UserFilter {
    creationInstantFrom?: Instant;
    creationInstantTo?: Instant;
    name?: string;
    role?: string;
}

export interface UserFormDTO {
    audit?: AuditDTO;
    authorities?: string[];
    avatar?: FwFileDTO;
    blocked?: boolean;
    email?: string;
    firstName?: string;
    id?: number;
    imageUrl?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    resetKey?: string;
    resetLink?: string;
    validated?: boolean;
    validationKey?: string;
    validationLink?: string;
    version?: number;
}

export interface UserFormNewDTO {
    authorities?: string[];
    email?: string;
    firstName?: string;
    imageUrl?: string;
    lastName?: string;
    login?: string;
    password?: string;
    validated?: boolean;
}

export interface UserListDTO {
    audit?: AuditDTO;
    authorities?: string[];
    blocked?: boolean;
    email?: string;
    id?: number;
    imageUrl?: string;
    lastLogin?: Instant;
    login?: string;
    validated?: boolean;
}

export function registerDefaultSerializers(config: ApinaConfig) {
    config.registerIdentitySerializer('Instant');

    config.registerIdentitySerializer('TipoPublicacion');

    config.registerClassSerializer('AccountDTO', {
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'avatarUrl': 'string',
        'email': 'string',
        'firstName': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'loginUserOriginal': 'string',
        'validated': 'boolean',
        'version': 'number'
    });

    config.registerClassSerializer('AuditDTO', {
        'createdBy': 'string',
        'createdDate': 'Instant',
        'lastModifiedBy': 'string',
        'lastModifiedDate': 'Instant'
    });

    config.registerClassSerializer('AutorDTO', {
        'apellidos': 'string',
        'audit': 'AuditDTO',
        'id': 'number',
        'label': 'string',
        'nombre': 'string'
    });

    config.registerClassSerializer('AutorFilter', {
        'apellidos': 'string',
        'conjuntoNombre': 'string',
        'nombre': 'string'
    });

    config.registerClassSerializer('AutorListDTO', {
        'apellidos': 'string',
        'id': 'number',
        'label': 'string',
        'nombre': 'string'
    });

    config.registerClassSerializer('AutorPaginaDTO', {
        'numero': 'string',
        'paginas': 'PublicPaginaDTO[]',
        'posicionNumero': 'number'
    });

    config.registerClassSerializer('AutorPublicacionDTO', {
        'autor': 'AutorDTO',
        'id': 'number',
        'traductor': 'boolean'
    });

    config.registerClassSerializer('BasicDTO', {
        'id': 'number',
        'label': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('BasicPublicacionPaginaDTO', {
        'id': 'number',
        'pagina': 'BasicDTO',
        'publicacion': 'BasicDTO'
    });

    config.registerClassSerializer('BuscadorPublicacionDTO', {
        'autores': 'AutorDTO[]',
        'idNumero': 'number',
        'nombreNumero': 'string',
        'posicionPagina': 'number',
        'primeraPagina': 'string',
        'revistaDTO': 'BuscadorRevistaDTO',
        'titulo': 'string'
    });

    config.registerClassSerializer('BuscadorPublicacionNumeroDTO', {
        'publicPublicacionSearchDTO': 'BuscadorPublicacionDTO[]',
        'tituloNumero': 'string'
    });

    config.registerClassSerializer('BuscadorRevistaDTO', {
        'anioFin': 'number',
        'anioInicio': 'number',
        'id': 'number',
        'numeros': 'PublicNumeroDTO[]',
        'pais': 'BasicDTO',
        'presentacion': 'string',
        'slug': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('ContactoDTO', {
        'content': 'string',
        'email': 'string',
        'nombre': 'string'
    });

    config.registerClassSerializer('DocumentacionDTO', {
        'indiceImagenes': 'FwFileDTO',
        'indiceOnomastico': 'FwFileDTO',
        'indiceTextos': 'FwFileDTO',
        'revista': 'BasicDTO'
    });

    config.registerClassSerializer('EntityDTO', {
        'field': 'boolean',
        'name': 'string',
        'type': 'FieldTypeDTO'
    });

    config.registerClassSerializer('FieldTypeDTO', {
        'className': 'string',
        'genericClass': 'string',
        'genericClassEnum': 'boolean'
    });

    config.registerClassSerializer('FwFileDTO', {
        'id': 'number',
        'name': 'string',
        'temporalFileName': 'string'
    });

    config.registerClassSerializer('InvestigadorDTO', {
        'apellidos': 'string',
        'email': 'string',
        'id': 'number',
        'nombre': 'string',
        'otrosDatos': 'string',
        'url': 'string'
    });

    config.registerClassSerializer('InvestigadorFilter', {
        'apellidos': 'string',
        'email': 'string',
        'nombre': 'string',
        'otrosDatos': 'string',
        'url': 'string'
    });

    config.registerClassSerializer('InvestigadorListDTO', {
        'apellidos': 'string',
        'email': 'string',
        'id': 'number',
        'nombre': 'string',
        'otrosDatos': 'string',
        'url': 'string'
    });

    config.registerClassSerializer('KeyAndPasswordDTO', {
        'key': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('LanguageDTO', {
        'code': 'string',
        'default': 'boolean',
        'name': 'string',
        'translation': 'string'
    });

    config.registerClassSerializer('NumeroDTO', {
        'desfasePdf': 'number',
        'id': 'number',
        'paginaInicio': 'number',
        'paginas': 'PaginaDTO[]',
        'pdfCompleto': 'FwFileDTO',
        'posicion': 'number',
        'revista': 'BasicDTO',
        'titulo': 'string'
    });

    config.registerClassSerializer('NumeroFilter', {
        'hasPdf': 'boolean',
        'revista': 'BasicDTO',
        'titulo': 'string'
    });

    config.registerClassSerializer('NumeroListDTO', {
        'id': 'number',
        'paginas': 'BasicDTO',
        'posicion': 'number',
        'revista': 'BasicDTO',
        'titulo': 'string'
    });

    config.registerClassSerializer('NumeroPaginas', {
        'paginas': 'string[]',
        'titulo': 'string'
    });

    config.registerClassSerializer('PaginaDTO', {
        'id': 'number',
        'imagen': 'FwFileDTO',
        'nombre': 'string',
        'numero': 'BasicDTO',
        'posicion': 'number',
        'publicaciones': 'PublicacionPaginaDTO[]'
    });

    config.registerClassSerializer('PaisDTO', {
        'audit': 'AuditDTO',
        'id': 'number',
        'nombre': 'string'
    });

    config.registerClassSerializer('PaisFilter', {
        'nombre': 'string'
    });

    config.registerClassSerializer('PaisListDTO', {
        'id': 'number',
        'nombre': 'string'
    });

    config.registerClassSerializer('PasswordChangeDTO', {
        'currentPassword': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('PublicAutorDTO', {
        'apellidos': 'string',
        'label': 'string',
        'nombre': 'string',
        'paginas': 'AutorPaginaDTO[]'
    });

    config.registerClassSerializer('PublicBuscadorPublicacionesRevistaDTO', {
        'buscadorRevistaDTO': 'BuscadorRevistaDTO',
        'publicPublicacionSearchGroupNumbers': 'BuscadorPublicacionNumeroDTO[]'
    });

    config.registerClassSerializer('PublicNumeroDTO', {
        'idPortada': 'number',
        'posicion': 'number',
        'titulo': 'string'
    });

    config.registerClassSerializer('PublicPaginaDTO', {
        'nombre': 'string',
        'posicion': 'number'
    });

    config.registerClassSerializer('PublicPublicacionDTO', {
        'autores': 'AutorDTO[]',
        'numeroPaginas': 'NumeroPaginas[]',
        'posicionPagina': 'number',
        'primeraPagina': 'number',
        'titulo': 'string'
    });

    config.registerClassSerializer('PublicPublicacionVisorDTO', {
        'autores': 'AutorDTO[]',
        'posicionPagina': 'number',
        'tipo': 'TipoPublicacion',
        'titulo': 'string',
        'traductores': 'AutorDTO[]'
    });

    config.registerClassSerializer('PublicRevistaCountryDTO', {
        'pais': 'BasicDTO',
        'revistas': 'PublicRevistaListDTO[]'
    });

    config.registerClassSerializer('PublicRevistaDTO', {
        'anioFin': 'number',
        'anioInicio': 'number',
        'autores': 'PublicAutorDTO[]',
        'ilustaciones': 'PublicPublicacionDTO[]',
        'numeros': 'PublicNumeroDTO[]',
        'presentacion': 'string',
        'textos': 'PublicPublicacionDTO[]',
        'titulo': 'string'
    });

    config.registerClassSerializer('PublicRevistaListDTO', {
        'anioFin': 'number',
        'anioInicio': 'number',
        'id': 'number',
        'portada': 'FwFileDTO',
        'posicion': 'number',
        'slug': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('PublicacionDetailsDTO', {
        'autores': 'AutorPublicacionDTO[]',
        'genero': 'string',
        'id': 'number',
        'notas': 'string',
        'paginas': 'BasicPublicacionPaginaDTO[]',
        'posicionPaginas': 'number[]',
        'tipo': 'TipoPublicacion',
        'tipologia': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('PublicacionFilter', {
        'id': 'number',
        'nombreAutor': 'string',
        'tipo': 'TipoPublicacion',
        'titulo': 'string'
    });

    config.registerClassSerializer('PublicacionInfoFilter', {
        'numPos': 'number',
        'paginas': 'number[]',
        'revSlug': 'string'
    });

    config.registerClassSerializer('PublicacionPaginaDTO', {
        'pagina': 'BasicDTO',
        'publicacion': 'PublicacionDetailsDTO'
    });

    config.registerClassSerializer('RevistaBuscadorFilter', {
        'nombrePais': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('RevistaDTO', {
        'anioFin': 'number',
        'anioInicio': 'number',
        'id': 'number',
        'numeros': 'BasicDTO',
        'pais': 'BasicDTO',
        'portada': 'FwFileDTO',
        'posicion': 'number',
        'presentacion': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('RevistaFilter', {
        'pais': 'BasicDTO',
        'posicion': 'number',
        'presentacion': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('RevistaListDTO', {
        'id': 'number',
        'numeros': 'BasicDTO',
        'pais': 'BasicDTO',
        'posicion': 'number',
        'presentacion': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('StaticDTO', {
        'descriptions': 'StaticI18nListDTO[]',
        'id': 'number',
        'name': 'string'
    });

    config.registerClassSerializer('StaticFilter', {
        'name': 'string'
    });

    config.registerClassSerializer('StaticI18nDTO', {
        'content': 'string',
        'id': 'number',
        'language': 'LanguageDTO',
        'name': 'string',
        'title': 'string'
    });

    config.registerClassSerializer('StaticI18nListDTO', {
        'content': 'string',
        'id': 'number',
        'language': 'LanguageDTO',
        'title': 'string'
    });

    config.registerClassSerializer('StaticListDTO', {
        'id': 'number',
        'languages': 'LanguageDTO[]',
        'name': 'string'
    });

    config.registerClassSerializer('UserBasicDTO', {
        'id': 'number',
        'label': 'string',
        'login': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFilter', {
        'creationInstantFrom': 'Instant',
        'creationInstantTo': 'Instant',
        'name': 'string',
        'role': 'string'
    });

    config.registerClassSerializer('UserFormDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'blocked': 'boolean',
        'email': 'string',
        'firstName': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'resetKey': 'string',
        'resetLink': 'string',
        'validated': 'boolean',
        'validationKey': 'string',
        'validationLink': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFormNewDTO', {
        'authorities': 'string[]',
        'email': 'string',
        'firstName': 'string',
        'imageUrl': 'string',
        'lastName': 'string',
        'login': 'string',
        'password': 'string',
        'validated': 'boolean'
    });

    config.registerClassSerializer('UserListDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'blocked': 'boolean',
        'email': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'lastLogin': 'Instant',
        'login': 'string',
        'validated': 'boolean'
    });

}

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private serializers: SerializerMap = {
        any: identitySerializer,
        string: identitySerializer,
        number: identitySerializer,
        boolean: identitySerializer
    };

    constructor() {
        registerDefaultSerializers(this);
    }

    serialize(value: any, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: any, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, enumSerializer(enumObject));
    }

    registerClassSerializer(name: string, fields: any) {
        this.registerSerializer(name, this.classSerializer(fields));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, identitySerializer);
    }

    registerDiscriminatedUnionSerializer(name: string, discriminator: string, types: { [key: string]: string; }) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    }

    private classSerializer(fields: any): Serializer {
        function mapProperties(obj: any, propertyMapper: (value: any, type: string) => any) {
            if (obj === null || obj === undefined) {
                return obj;
            }

            const result: any = {};

            for (const name in fields) {
                if (fields.hasOwnProperty(name)) {
                    const value: any = obj[name];
                    const type: string = fields[name];
                    result[name] = propertyMapper(value, type);
                }
            }

            return result;
        }

        const serialize = this.serialize.bind(this);
        const deserialize = this.deserialize.bind(this);
        return {
            serialize(obj) {
                return mapProperties(obj, serialize);
            },
            deserialize(obj) {
                return mapProperties(obj, deserialize);
            }
        };
    }

    private discriminatedUnionSerializer(discriminatorProperty: string, types: { [key: string]: string; }): Serializer {
        const resolveSerializer = (localType: string) => {
            return this.lookupSerializer(types[localType]);
        };

        return {
            serialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.indexOf('[]', type.length - 2) !== -1) { // type.endsWith('[]')
            const elementType = type.substring(0, type.length - 2);
            const elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }
        const serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        } else {
            throw new Error(`could not find serializer for type '${type}'`);
        }
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    function safeMap(value: any[], mapper: (a: any) => any) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }

    return {
        serialize(value) {
            return safeMap(value, elementSerializer.serialize.bind(elementSerializer));
        },
        deserialize(value) {
            return safeMap(value, elementSerializer.deserialize.bind(elementSerializer));
        }
    }
}

export interface RequestData {
    uriTemplate: string;
    method: string;
    pathVariables?: any;
    requestParams?: any;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer {
    serialize(o: any): any;
    deserialize(o: any): any;
}

const identitySerializer: Serializer = {
    serialize(o) {
        return o;
    },
    deserialize(o) {
        return o;
    }
};

function enumSerializer(enumObject: any): Serializer {
    return {
        serialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    }
}

interface SerializerMap {
    [name: string]: Serializer;
}

export abstract class ApinaEndpointContext {

    constructor(protected config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }


        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

@Injectable( {providedIn: 'root'} )
export class AccountResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    changePassword(passwordChangeDto: PasswordChangeDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/account/change-password',
            'method': 'POST',
            'requestBody': this.context.serialize(passwordChangeDto, 'PasswordChangeDTO')
        });
    }

    changePasswordUrl(passwordChangeDto: PasswordChangeDTO): string {
        return this.context.buildUrl('/api/account/change-password' , null);
    }

    finishPasswordReset(keyAndPassword: KeyAndPasswordDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/account/reset-password/finish',
            'method': 'POST',
            'requestBody': this.context.serialize(keyAndPassword, 'KeyAndPasswordDTO')
        });
    }

    finishPasswordResetUrl(keyAndPassword: KeyAndPasswordDTO): string {
        return this.context.buildUrl('/api/account/reset-password/finish' , null);
    }

    getAccount(): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/api/account',
            'method': 'GET',
            'responseType': 'AccountDTO'
        });
    }

    getAccountUrl(): string {
        return this.context.buildUrl('/api/account' , null);
    }

    isAuthenticated(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/api/authenticate',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    isAuthenticatedUrl(): string {
        return this.context.buildUrl('/api/authenticate' , null);
    }

    registerAccount(userFormNewDTO: UserFormNewDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/register',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormNewDTO, 'UserFormNewDTO')
        });
    }

    registerAccountUrl(userFormNewDTO: UserFormNewDTO): string {
        return this.context.buildUrl('/api/register' , null);
    }

    requestPasswordReset(mail: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/account/reset-password/init',
            'method': 'POST',
            'requestBody': this.context.serialize(mail, 'string')
        });
    }

    requestPasswordResetUrl(mail: string): string {
        return this.context.buildUrl('/api/account/reset-password/init' , null);
    }

    saveAccount(accountDTO: AccountDTO): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/api/account',
            'method': 'POST',
            'requestBody': this.context.serialize(accountDTO, 'AccountDTO'),
            'responseType': 'AccountDTO'
        });
    }

    saveAccountUrl(accountDTO: AccountDTO): string {
        return this.context.buildUrl('/api/account' , null);
    }

    validateAccount(key: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/validate',
            'method': 'GET',
            'requestParams': {
                'key': this.context.serialize(key, 'string')
            }
        });
    }

    validateAccountUrl(key: string): string {
        return this.context.buildUrl('/api/validate' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class AutorResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(autorDTO: AutorDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/autores',
            'method': 'POST',
            'requestBody': this.context.serialize(autorDTO, 'AutorDTO'),
            'responseType': 'number'
        });
    }

    createUrl(autorDTO: AutorDTO): string {
        return this.context.buildUrl('/autores' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/autores/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/autores/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: AutorFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/autores',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'AutorFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: AutorFilter, page: Pageable): string {
        return this.context.buildUrl('/autores' , null);
    }

    findAllBasic(filter: AutorFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/autores/find-all-basic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'AutorFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: AutorFilter, page: Pageable): string {
        return this.context.buildUrl('/autores/find-all-basic' , null);
    }

    get(id: number): Observable<AutorDTO> {
        return this.context.request({
            'uriTemplate': '/autores/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'AutorDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/autores/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, autorDTO: AutorDTO): Observable<AutorDTO> {
        return this.context.request({
            'uriTemplate': '/autores/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(autorDTO, 'AutorDTO'),
            'responseType': 'AutorDTO'
        });
    }

    updateUrl(id: number, autorDTO: AutorDTO): string {
        return this.context.buildUrl('/autores/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class ContactoResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    sendContactMail(contactoDTO: ContactoDTO, token: string): Observable<boolean> {
        return this.context.request({
            'uriTemplate': '/contacto',
            'method': 'POST',
            'requestParams': {
                'token': this.context.serialize(token, 'string')
            },
            'requestBody': this.context.serialize(contactoDTO, 'ContactoDTO'),
            'responseType': 'boolean'
        });
    }

    sendContactMailUrl(contactoDTO: ContactoDTO, token: string): string {
        return this.context.buildUrl('/contacto' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class DocumentResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    test(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/documents/test',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    testUrl(): string {
        return this.context.buildUrl('/documents/test' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class EntityResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    findAll(entityName: string): Observable<EntityDTO[]> {
        return this.context.request({
            'uriTemplate': '/entities',
            'method': 'GET',
            'requestParams': {
                'entityName': this.context.serialize(entityName, 'string')
            },
            'responseType': 'EntityDTO[]'
        });
    }

    findAllUrl(entityName: string): string {
        return this.context.buildUrl('/entities' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class EnumResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getTipoPublicacion(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/tipo-publicacion',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getTipoPublicacionUrl(): string {
        return this.context.buildUrl('/enum/tipo-publicacion' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class FicheroResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    downloadTemporalFile(temporalFileName: string, fileName: string, fileCodification: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/files/{temporalFileName}',
            'method': 'GET',
            'pathVariables': {
                'temporalFileName': this.context.serialize(temporalFileName, 'string')
            },
            'requestParams': {
                'fileName': this.context.serialize(fileName, 'string'),
                'fileCodification': this.context.serialize(fileCodification, 'string')
            }
        });
    }

    downloadTemporalFileUrl(temporalFileName: string, fileName: string, fileCodification: string): string {
        return this.context.buildUrl('/files/{temporalFileName}' , {
            'temporalFileName': this.context.serialize(temporalFileName, 'string')
        });
    }

    saveTemporalFile(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/files/temporal',
            'method': 'POST',
            'responseType': 'string'
        });
    }

    saveTemporalFileUrl(): string {
        return this.context.buildUrl('/files/temporal' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class InvestigadorResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(investigadorDTO: InvestigadorDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/investigadors',
            'method': 'POST',
            'requestBody': this.context.serialize(investigadorDTO, 'InvestigadorDTO'),
            'responseType': 'number'
        });
    }

    createUrl(investigadorDTO: InvestigadorDTO): string {
        return this.context.buildUrl('/investigadors' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/investigadors/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/investigadors/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: InvestigadorFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/investigadors',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'InvestigadorFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: InvestigadorFilter, page: Pageable): string {
        return this.context.buildUrl('/investigadors' , null);
    }

    get(id: number): Observable<InvestigadorDTO> {
        return this.context.request({
            'uriTemplate': '/investigadors/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'InvestigadorDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/investigadors/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, investigadorDTO: InvestigadorDTO): Observable<InvestigadorDTO> {
        return this.context.request({
            'uriTemplate': '/investigadors/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(investigadorDTO, 'InvestigadorDTO'),
            'responseType': 'InvestigadorDTO'
        });
    }

    updateUrl(id: number, investigadorDTO: InvestigadorDTO): string {
        return this.context.buildUrl('/investigadors/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class LanguageResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getCurrentLanguage(): Observable<LanguageDTO> {
        return this.context.request({
            'uriTemplate': '/languages/current',
            'method': 'GET',
            'responseType': 'LanguageDTO'
        });
    }

    getCurrentLanguageUrl(): string {
        return this.context.buildUrl('/languages/current' , null);
    }

    getLanguages(): Observable<LanguageDTO[]> {
        return this.context.request({
            'uriTemplate': '/languages',
            'method': 'GET',
            'responseType': 'LanguageDTO[]'
        });
    }

    getLanguagesUrl(): string {
        return this.context.buildUrl('/languages' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class NumeroResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    countPaginas(revSlug: string, numPos: number): Observable<number> {
        return this.context.request({
            'uriTemplate': '/numeros/count-pagina/{revSlug}/{numPos}',
            'method': 'GET',
            'pathVariables': {
                'revSlug': this.context.serialize(revSlug, 'string'),
                'numPos': this.context.serialize(numPos, 'number')
            },
            'responseType': 'number'
        });
    }

    countPaginasUrl(revSlug: string, numPos: number): string {
        return this.context.buildUrl('/numeros/count-pagina/{revSlug}/{numPos}' , {
            'revSlug': this.context.serialize(revSlug, 'string'),
            'numPos': this.context.serialize(numPos, 'number')
        });
    }

    create(numeroDTO: NumeroDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/numeros',
            'method': 'POST',
            'requestBody': this.context.serialize(numeroDTO, 'NumeroDTO'),
            'responseType': 'number'
        });
    }

    createUrl(numeroDTO: NumeroDTO): string {
        return this.context.buildUrl('/numeros' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/numeros/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/numeros/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: NumeroFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/numeros',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'NumeroFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: NumeroFilter, page: Pageable): string {
        return this.context.buildUrl('/numeros' , null);
    }

    findAllBasic(filter: NumeroFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/numeros/find-all-basic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'NumeroFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: NumeroFilter, page: Pageable): string {
        return this.context.buildUrl('/numeros/find-all-basic' , null);
    }

    findAllNumeroDTO(filter: NumeroFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/numeros/find-all-numeroDto',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'NumeroFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllNumeroDTOUrl(filter: NumeroFilter, page: Pageable): string {
        return this.context.buildUrl('/numeros/find-all-numeroDto' , null);
    }

    get(id: number): Observable<NumeroDTO> {
        return this.context.request({
            'uriTemplate': '/numeros/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'NumeroDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/numeros/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPagina(revSlug: string, numPos: number, pagina: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/numeros/get-pagina/{revSlug}/{numPos}/{pagina}',
            'method': 'GET',
            'pathVariables': {
                'revSlug': this.context.serialize(revSlug, 'string'),
                'numPos': this.context.serialize(numPos, 'number'),
                'pagina': this.context.serialize(pagina, 'number')
            }
        });
    }

    getPaginaUrl(revSlug: string, numPos: number, pagina: number): string {
        return this.context.buildUrl('/numeros/get-pagina/{revSlug}/{numPos}/{pagina}' , {
            'revSlug': this.context.serialize(revSlug, 'string'),
            'numPos': this.context.serialize(numPos, 'number'),
            'pagina': this.context.serialize(pagina, 'number')
        });
    }

    getPdfCompleto(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/numeros/{id}/pdfCompleto',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getPdfCompletoUrl(id: number): string {
        return this.context.buildUrl('/numeros/{id}/pdfCompleto' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, numeroDTO: NumeroDTO): Observable<NumeroDTO> {
        return this.context.request({
            'uriTemplate': '/numeros/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(numeroDTO, 'NumeroDTO'),
            'responseType': 'NumeroDTO'
        });
    }

    updateUrl(id: number, numeroDTO: NumeroDTO): string {
        return this.context.buildUrl('/numeros/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    updatePositions(numeroIds: number[]): Observable<void> {
        return this.context.request({
            'uriTemplate': '/numeros/sort',
            'method': 'PUT',
            'requestBody': this.context.serialize(numeroIds, 'number[]')
        });
    }

    updatePositionsUrl(numeroIds: number[]): string {
        return this.context.buildUrl('/numeros/sort' , null);
    }

    uploadPdf(id: number, numeroDTO: NumeroDTO): Observable<NumeroDTO> {
        return this.context.request({
            'uriTemplate': '/numeros/uploadPdf/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(numeroDTO, 'NumeroDTO'),
            'responseType': 'NumeroDTO'
        });
    }

    uploadPdfUrl(id: number, numeroDTO: NumeroDTO): string {
        return this.context.buildUrl('/numeros/uploadPdf/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class PaginaResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(numeroDTO: PaginaDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/paginas',
            'method': 'POST',
            'requestBody': this.context.serialize(numeroDTO, 'PaginaDTO'),
            'responseType': 'number'
        });
    }

    createUrl(numeroDTO: PaginaDTO): string {
        return this.context.buildUrl('/paginas' , null);
    }

    createAndReturn(numeroDTO: PaginaDTO): Observable<PaginaDTO> {
        return this.context.request({
            'uriTemplate': '/paginas/create',
            'method': 'POST',
            'requestBody': this.context.serialize(numeroDTO, 'PaginaDTO'),
            'responseType': 'PaginaDTO'
        });
    }

    createAndReturnUrl(numeroDTO: PaginaDTO): string {
        return this.context.buildUrl('/paginas/create' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/paginas/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/paginas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    descargarImagenConDimensiones(ficheroId: number, fileDimensions: string, nombreImagen: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/paginas/imagen/{ficheroId}/{fileDimensions}/{nombreImagen}',
            'method': 'GET',
            'pathVariables': {
                'ficheroId': this.context.serialize(ficheroId, 'number'),
                'fileDimensions': this.context.serialize(fileDimensions, 'string'),
                'nombreImagen': this.context.serialize(nombreImagen, 'string')
            }
        });
    }

    descargarImagenConDimensionesUrl(ficheroId: number, fileDimensions: string, nombreImagen: string): string {
        return this.context.buildUrl('/paginas/imagen/{ficheroId}/{fileDimensions}/{nombreImagen}' , {
            'ficheroId': this.context.serialize(ficheroId, 'number'),
            'fileDimensions': this.context.serialize(fileDimensions, 'string'),
            'nombreImagen': this.context.serialize(nombreImagen, 'string')
        });
    }

    descargarPortadaConDimensiones(id: number, fileDimensions: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/paginas/imagen/{id}/{fileDimensions}/portada',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number'),
                'fileDimensions': this.context.serialize(fileDimensions, 'string')
            }
        });
    }

    descargarPortadaConDimensionesUrl(id: number, fileDimensions: string): string {
        return this.context.buildUrl('/paginas/imagen/{id}/{fileDimensions}/portada' , {
            'id': this.context.serialize(id, 'number'),
            'fileDimensions': this.context.serialize(fileDimensions, 'string')
        });
    }

    findAllNumeroDTO(id: number): Observable<PaginaDTO[]> {
        return this.context.request({
            'uriTemplate': '/paginas/numero/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PaginaDTO[]'
        });
    }

    findAllNumeroDTOUrl(id: number): string {
        return this.context.buildUrl('/paginas/numero/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPortada(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/paginas/{id}/portada',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getPortadaUrl(id: number): string {
        return this.context.buildUrl('/paginas/{id}/portada' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    updateName(id: number, nombre: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/paginas/name/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(nombre, 'string')
        });
    }

    updateNameUrl(id: number, nombre: string): string {
        return this.context.buildUrl('/paginas/name/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    updatePositions(numeroIds: number[]): Observable<void> {
        return this.context.request({
            'uriTemplate': '/paginas/sort',
            'method': 'PUT',
            'requestBody': this.context.serialize(numeroIds, 'number[]')
        });
    }

    updatePositionsUrl(numeroIds: number[]): string {
        return this.context.buildUrl('/paginas/sort' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class PaisResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(paisDTO: PaisDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/paises',
            'method': 'POST',
            'requestBody': this.context.serialize(paisDTO, 'PaisDTO'),
            'responseType': 'number'
        });
    }

    createUrl(paisDTO: PaisDTO): string {
        return this.context.buildUrl('/paises' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/paises/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/paises/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: PaisFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/paises',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PaisFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: PaisFilter, page: Pageable): string {
        return this.context.buildUrl('/paises' , null);
    }

    findAllBasic(filter: PaisFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/paises/find-all-basic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PaisFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: PaisFilter, page: Pageable): string {
        return this.context.buildUrl('/paises/find-all-basic' , null);
    }

    get(id: number): Observable<PaisDTO> {
        return this.context.request({
            'uriTemplate': '/paises/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PaisDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/paises/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, paisDTO: PaisDTO): Observable<PaisDTO> {
        return this.context.request({
            'uriTemplate': '/paises/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(paisDTO, 'PaisDTO'),
            'responseType': 'PaisDTO'
        });
    }

    updateUrl(id: number, paisDTO: PaisDTO): string {
        return this.context.buildUrl('/paises/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class PublicacionResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(publicacionDetailsDTO: PublicacionDetailsDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/publicaciones',
            'method': 'POST',
            'requestBody': this.context.serialize(publicacionDetailsDTO, 'PublicacionDetailsDTO'),
            'responseType': 'number'
        });
    }

    createUrl(publicacionDetailsDTO: PublicacionDetailsDTO): string {
        return this.context.buildUrl('/publicaciones' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/publicaciones/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/publicaciones/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: PublicacionFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/publicaciones',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PublicacionFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: PublicacionFilter, page: Pageable): string {
        return this.context.buildUrl('/publicaciones' , null);
    }

    findAllPublicationCoincidences(filter: PublicacionFilter): Observable<PublicBuscadorPublicacionesRevistaDTO[]> {
        return this.context.request({
            'uriTemplate': '/publicaciones/searcher-publicaciones',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PublicacionFilter')
            },
            'responseType': 'PublicBuscadorPublicacionesRevistaDTO[]'
        });
    }

    findAllPublicationCoincidencesUrl(filter: PublicacionFilter): string {
        return this.context.buildUrl('/publicaciones/searcher-publicaciones' , null);
    }

    get(id: number): Observable<PublicacionDetailsDTO> {
        return this.context.request({
            'uriTemplate': '/publicaciones/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PublicacionDetailsDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/publicaciones/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getMetadata(filter: PublicacionInfoFilter): Observable<PublicPublicacionVisorDTO[]> {
        return this.context.request({
            'uriTemplate': '/publicaciones/info',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PublicacionInfoFilter')
            },
            'responseType': 'PublicPublicacionVisorDTO[]'
        });
    }

    getMetadataUrl(filter: PublicacionInfoFilter): string {
        return this.context.buildUrl('/publicaciones/info' , null);
    }

    update(id: number, publicacionDetailsDTO: PublicacionDetailsDTO): Observable<PublicacionDetailsDTO> {
        return this.context.request({
            'uriTemplate': '/publicaciones/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(publicacionDetailsDTO, 'PublicacionDetailsDTO'),
            'responseType': 'PublicacionDetailsDTO'
        });
    }

    updateUrl(id: number, publicacionDetailsDTO: PublicacionDetailsDTO): string {
        return this.context.buildUrl('/publicaciones/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class RevistaResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(revistaDTO: RevistaDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/revistas',
            'method': 'POST',
            'requestBody': this.context.serialize(revistaDTO, 'RevistaDTO'),
            'responseType': 'number'
        });
    }

    createUrl(revistaDTO: RevistaDTO): string {
        return this.context.buildUrl('/revistas' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/revistas/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/revistas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: RevistaFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/revistas',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'RevistaFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: RevistaFilter, page: Pageable): string {
        return this.context.buildUrl('/revistas' , null);
    }

    findAllBasic(filter: RevistaFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/revistas/find-all-basic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'RevistaFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: RevistaFilter, page: Pageable): string {
        return this.context.buildUrl('/revistas/find-all-basic' , null);
    }

    findAllBasicNoImportada(page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/revistas/find-all-basic-unimported',
            'method': 'GET',
            'requestParams': {
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicNoImportadaUrl(page: Pageable): string {
        return this.context.buildUrl('/revistas/find-all-basic-unimported' , null);
    }

    findAllCoincidences(filter: RevistaBuscadorFilter): Observable<PublicRevistaListDTO[]> {
        return this.context.request({
            'uriTemplate': '/revistas/find-all-revista-coincidences',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'RevistaBuscadorFilter')
            },
            'responseType': 'PublicRevistaListDTO[]'
        });
    }

    findAllCoincidencesUrl(filter: RevistaBuscadorFilter): string {
        return this.context.buildUrl('/revistas/find-all-revista-coincidences' , null);
    }

    findAllPublic(filter: RevistaFilter): Observable<PublicRevistaCountryDTO[]> {
        return this.context.request({
            'uriTemplate': '/revistas/find-all-public',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'RevistaFilter')
            },
            'responseType': 'PublicRevistaCountryDTO[]'
        });
    }

    findAllPublicUrl(filter: RevistaFilter): string {
        return this.context.buildUrl('/revistas/find-all-public' , null);
    }

    findBySlug(slug: string): Observable<PublicRevistaDTO> {
        return this.context.request({
            'uriTemplate': '/revistas/slug/{slug}',
            'method': 'GET',
            'pathVariables': {
                'slug': this.context.serialize(slug, 'string')
            },
            'responseType': 'PublicRevistaDTO'
        });
    }

    findBySlugUrl(slug: string): string {
        return this.context.buildUrl('/revistas/slug/{slug}' , {
            'slug': this.context.serialize(slug, 'string')
        });
    }

    get(id: number): Observable<RevistaDTO> {
        return this.context.request({
            'uriTemplate': '/revistas/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'RevistaDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/revistas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPortada(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/revistas/{id}/portada',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getPortadaUrl(id: number): string {
        return this.context.buildUrl('/revistas/{id}/portada' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPortadaThumnail(id: number, fileDimensions: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/revistas/{id}/{fileDimensions}/portada',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number'),
                'fileDimensions': this.context.serialize(fileDimensions, 'string')
            }
        });
    }

    getPortadaThumnailUrl(id: number, fileDimensions: string): string {
        return this.context.buildUrl('/revistas/{id}/{fileDimensions}/portada' , {
            'id': this.context.serialize(id, 'number'),
            'fileDimensions': this.context.serialize(fileDimensions, 'string')
        });
    }

    update(id: number, revistaDTO: RevistaDTO): Observable<RevistaDTO> {
        return this.context.request({
            'uriTemplate': '/revistas/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(revistaDTO, 'RevistaDTO'),
            'responseType': 'RevistaDTO'
        });
    }

    updateUrl(id: number, revistaDTO: RevistaDTO): string {
        return this.context.buildUrl('/revistas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    uploadFromExcel(documentacionDTO: DocumentacionDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/revistas/importService',
            'method': 'POST',
            'requestBody': this.context.serialize(documentacionDTO, 'DocumentacionDTO')
        });
    }

    uploadFromExcelUrl(documentacionDTO: DocumentacionDTO): string {
        return this.context.buildUrl('/revistas/importService' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class StaticI18nResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getByName(name: string): Observable<StaticI18nDTO> {
        return this.context.request({
            'uriTemplate': '/static-i18n/name/{name}',
            'method': 'GET',
            'pathVariables': {
                'name': this.context.serialize(name, 'string')
            },
            'responseType': 'StaticI18nDTO'
        });
    }

    getByNameUrl(name: string): string {
        return this.context.buildUrl('/static-i18n/name/{name}' , {
            'name': this.context.serialize(name, 'string')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class StaticResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(estaticaDTO: StaticDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/static',
            'method': 'POST',
            'requestBody': this.context.serialize(estaticaDTO, 'StaticDTO'),
            'responseType': 'number'
        });
    }

    createUrl(estaticaDTO: StaticDTO): string {
        return this.context.buildUrl('/static' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/static/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/static/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: StaticFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/static',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'StaticFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: StaticFilter, page: Pageable): string {
        return this.context.buildUrl('/static' , null);
    }

    get(id: number): Observable<StaticDTO> {
        return this.context.request({
            'uriTemplate': '/static/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'StaticDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/static/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getByName(name: string): Observable<StaticDTO> {
        return this.context.request({
            'uriTemplate': '/static/name/{name}',
            'method': 'GET',
            'pathVariables': {
                'name': this.context.serialize(name, 'string')
            },
            'responseType': 'StaticDTO'
        });
    }

    getByNameUrl(name: string): string {
        return this.context.buildUrl('/static/name/{name}' , {
            'name': this.context.serialize(name, 'string')
        });
    }

    update(id: number, estaticaDTO: StaticDTO): Observable<StaticDTO> {
        return this.context.request({
            'uriTemplate': '/static/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(estaticaDTO, 'StaticDTO'),
            'responseType': 'StaticDTO'
        });
    }

    updateUrl(id: number, estaticaDTO: StaticDTO): string {
        return this.context.buildUrl('/static/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class UserResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(userFormDTO: UserFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filtro: UserFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'UserFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: UserFilter, pageable: Pageable): string {
        return this.context.buildUrl('/users' , null);
    }

    findAllOrdenadosPorLogin(): Observable<UserBasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/users/todos',
            'method': 'GET',
            'responseType': 'UserBasicDTO[]'
        });
    }

    findAllOrdenadosPorLoginUrl(): string {
        return this.context.buildUrl('/users/todos' , null);
    }

    get(id: number): Observable<UserFormDTO> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'UserFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getAuthorities(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/users/authorities',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getAuthoritiesUrl(): string {
        return this.context.buildUrl('/users/authorities' , null);
    }

    getAvatar(login: string, avatarId: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{login}/{avatarId}/avatar.jpg',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string'),
                'avatarId': this.context.serialize(avatarId, 'string')
            }
        });
    }

    getAvatarUrl(login: string, avatarId: string): string {
        return this.context.buildUrl('/users/{login}/{avatarId}/avatar.jpg' , {
            'login': this.context.serialize(login, 'string'),
            'avatarId': this.context.serialize(avatarId, 'string')
        });
    }

    update(id: number, userFormDTO: UserFormDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO')
        });
    }

    updateUrl(id: number, userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}


export function apinaConfigFactory() {
    return new ApinaConfig();
}

@NgModule({
    imports: [HttpClientModule],
    providers: [
        { provide: ApinaEndpointContext, useClass: DefaultApinaEndpointContext },
        { provide: ApinaConfig, useFactory: apinaConfigFactory }
    ]
})
export class ApinaModule {}
