import {Component, OnInit} from '@angular/core';
import {USER_MENU} from '../menu/menu-user';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'enxe-public-layout',
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {

    navItems = USER_MENU;
    mostrarSidebar: any = false;
    hiddenSidebar = true;
    hiddenSearcher = false;
    isHome: boolean;
    isPublicaciones: boolean;

    textoBusqueda: string;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.textoBusqueda = null;
        this.checkUrl();
    }

    ngOnInit(): void {
        this.isHome = location.pathname === '/';
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isHome = event.url === '/';
                // Show loading indicator
            }
        });
        this.mostrarSidebar = 'sm';

        this.checkUrl();
    }

    toggleSidebar($event) {
        $event.preventDefault();
        this.hiddenSidebar = !this.hiddenSidebar;
    }

    toggleSearcher($event) {
        $event.preventDefault();
        this.hiddenSearcher = !this.hiddenSearcher;
    }

    globalToggle($event) {
        if (!this.hiddenSidebar) {
            this.hiddenSidebar = true;
        }

    }

    checkUrl() {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.textoBusqueda = null;
                if (this.router.url.includes('/publicaciones') || this.router.url.includes('/busqueda')) {
                    this.isPublicaciones = true;
                } else {
                    this.isPublicaciones = false;
                }

                if (!this.router.url.includes('/busqueda')) {
                    this.hiddenSearcher = false;
                } else {
                    this.fillSearchBar();
                }
            }
        });
    }

    fillSearchBar() {
        this.hiddenSearcher = true;
        this.activatedRoute.queryParams.subscribe(q => this.textoBusqueda = q.q);
    }

    isActive(url: string) {
        return this.router.isActive(url, false);
    }

    buscarPalabra(palabraBuscada: string) {
        this.router.navigate(['busqueda/'], {queryParams: {q: palabraBuscada}});
    }

    buscarPal() {
        const wordSearch = this.textoBusqueda;
        setTimeout(() => {
            if (wordSearch == this.textoBusqueda) {
                this.router.navigate(['busqueda/'], {queryParams: {q: this.textoBusqueda}});
            }
        }, 500);
    }
}
